
export const environment = {
  version: '1.21.5',
  production: true,
  old_url: 'https://sandbox.cdtsys.it',
  api: {
    endpoint: 'https://sandbox.cdtsys.it/api',
    client_id: '959d64e0-5f3d-4863-b2b7-8cd52655162b',
    client_secret: 'GHKOyocMvuQqs87pDeGq7aAvmQzcldeIGm9yvqe5',
  },
  google_maps_api_key: 'AIzaSyCSAAjIV0Y2X7Mg_PZLnYnSo5KYcFs3yuk',
  google_oauth2_client_id: '501459663065-u9svur67ti1ul187qddoqc428ru7ivna.apps.googleusercontent.com',
  rd_client_id: 'undefined',
  pusher_app_key: '5f4c82c13a6e9f267d30',
  pusher_cluster: 'mt1',
  logrocket: 'tlgo9t/cdtsys',
  max_upload_size: 314572800,
  languages: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'pt_BR': 'Brazilian Portuguese',
    'en': 'English',
    'es': 'Spanish'
  }
};
